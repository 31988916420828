<template>
  <div>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="dateValue"
          v-bind="attrs"
          outlined
          :label="labelValue"
          append-icon="mdi-calendar"
          :prepend-icon="prependIcon"
          readonly
          rounded
          :disabled="disabledInput"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dateValue"
        scrollable
        :disabled="disabled"
        :min="minDate"
        :max="maxDate"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="modal = false"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledInput: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const date = ref('');
    const modal = ref('');
    const dateValue = computed({
      get: () => props.value || props.defaultValue,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const labelValue = computed({
      get: () => props.label,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    return { date, modal, dateValue, labelValue };
  }
};
</script>
